import { usePublisherStore } from "~/store/PublisherModule";

type RequestMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface IRequestOptions {
  method?: RequestMethods;
  body?: any;
  params?: Record<string, any>;
  query?: string;
}

 // Start of Selection
export const simpleApi = async (endpoint: string, options: IRequestOptions = {}) => {
  const query = options.query || ''; // Получаем строку запроса или устанавливаем пустую строку, если она не задана
  try {
    const config = useRuntimeConfig(); // Получаем конфигурацию среды выполнения
    const store = usePublisherStore(); // Получаем доступ к хранилищу Publisher

    const apiPublisherUrl = config.public.LIGHT_API_ORIGIN; // URL API из конфигурации
    // Получаем токен из куки или из хранилища, если куки не определены
    const token = useCookie('authorized').value && useCookie('authorized').value !== 'undefined' ? useCookie('authorized').value : store.authorized;

    // Устанавливаем заголовки для запроса
    const headers = {
      'Content-Type': 'application/json', // Указываем тип содержимого
      'X-Api-Key': `${token}`, // Добавляем токен в заголовок
    };
    if (!token) {
      delete headers['X-Api-Key']; // Удаляем заголовок токена, если токен не определен
    }

    // Выполняем запрос с использованием $fetch
    const response = await $fetch(`${apiPublisherUrl}${endpoint}${query}`, {
      method: options.method || 'GET', // Устанавливаем метод запроса, по умолчанию GET
      body: options.body, // Передаем тело запроса, если оно есть
      headers, // Передаем заголовки
      params: options.params, // Передаем параметры запроса, если они есть
    });
    return response; // Возвращаем ответ

  } catch (error) {
    // Обрабатываем ошибку и выбрасываем новое исключение с сообщением об ошибке
    throw new Error(`Query error for ${endpoint}: ${error.message}`);
  }
}